<template>
  <div>
    <div class="agent-form">
      <el-header>
        <div
          :style="{color:currentTheme.logoTextColor}"
          class="logo-container">
          <img
            src="@/assets/images/logo1.png"
            alt="">
          <span>Bunker Chain</span>
        </div>
        <!-- <div class="top-container">
          <div class="right">
            语言切换
            <div
              class="top-block">
              <language-selector :style="{color:currentTheme.barTextColor}">
              </language-selector>
            </div>
          </div>
        </div> -->
      </el-header>
      <div class="container" v-if="isValid">
        <div class="email-detail">
          <!-- <p>Date: {{orderItem.createDate}}</p> -->
          <p>Dear Sirs,</p>
          <!-- <p class="main-text">
            Please fill in the following information to complete the registration.
          </p> -->
        </div>
        <p class="one-tip">Please fill in the following information to complete the registration.</p>
        <el-form  ref="userForm" label-position="top" label-width="230px" :model="userForm" :rules="rules">
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="Email" prop="email">
                <el-input v-model="userForm.email" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="IMO" prop="imo">
                <el-input v-model="userForm.imo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="Ship Name" prop="shipName">
                <el-input v-model="userForm.shipName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Type" prop="type">
                <el-select v-model="userForm.type" :placeholder="$t('pleaseSelect')">
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="Callsign" prop="callSign">
                <el-input v-model="userForm.callSign"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Gross Registered Tonnage" prop="grt">
                <el-input v-model="userForm.grt" type="number"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="Port of Registry" prop="registryPort">
                <el-input v-model="userForm.registryPort"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="License No." prop="licenceNo">
                <el-input v-model="userForm.licenceNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="Cell Phone Number" prop="cellPhone">
                <phone-input v-model="userForm.cellPhone"></phone-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Satellite Phone Number" prop="satellitePhone">
                <el-input v-model="userForm.satellitePhone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="Password" prop="password">
                <el-input type="password" v-model="userForm.password" show-password></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Confirm Password" prop="confirmPassword">
                <el-input type="password" v-model="userForm.confirmPassword" show-password></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="Ex-name" prop="exName">
                <el-input v-model="userForm.exName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Seal Verification Report No." prop="sealVerificationReportNo">
                <el-input v-model="userForm.sealVerificationReportNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="Meter Serial No." prop="meterSerialNo">
                <el-input v-model="userForm.meterSerialNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="Ship Stamp">
                <el-upload
                  :action="fileUploadUrl"
                  accept=".jpg,.png"
                  list-type="picture"
                  :on-change="onUploadStamp"
                  :auto-upload="false"
                >
                <el-button type="primary" plain round style="min-width:200px; font-size:14px;">Upload</el-button>
                <div slot="tip" class="el-upload__tip" style="margin: 0;">Only JPEG/PNG can be uploaded.</div>
              </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <div class="div-stamp-preview">
                <img v-if="croppedStampFile" :src="croppedStampFile.url"/>
                <div v-else>No stamp uploaded</div>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div class="btns">
          <el-button class="btn" @click="resetForm">{{$t('reset')}}</el-button>
          <el-button class="btn" type="primary" :loading="submitLoading" @click="submitForm">{{$t('submit')}}</el-button>
        </div>
        <p class="one-tip">Many thanks and have a great day ahead !</p>
        <p class="one-tip">By uploading and accepting the conditions or uploading any documents, you confirm and agree to the binding usage of Digital agreements of Bunkerchain Pte Ltd, if you have received this e-mail by mistake, please inform the user immediately or send a technical request to support@bunkerchain.io. Bunkerchain Pte Ltd reserve all rights, for a copy of our terms & conditions, please visit our website at www.bunkerchain.io, or request with the user administrator. Please note that any views or opinions presented in this email are solely those of the author and do not necessarily represent those of the company. Finally, the recipient should check this email and any attachments for the presence of viruses. The company accepts no liability for any damage caused by any virus transmitted by this email.</p>
      </div>
      <div v-else class="no-valid">
        {{$t('pageExpired')}}
      </div>
    </div>
    <el-dialog
      :visible="isCropStamp"
      title="Ship Stamp"
      width="50%"
      @close="isCropStamp = false"
      :close-on-click-modal="false"
    >
    <div v-loading="isStampLoading" style="min-height: 100px ;display: flex; justify-content: center;">
      <cropper
        ref="cropper"
        class="cropper"
        :src="(stampFile && stampFile.url) || ''"
        @change="handleStampCropChange"
        @ready="handleCropperReady"
      />
    </div>
    <div slot="footer" v-if="!isStampLoading" style="text-align: center;">
      <el-button
        round
        @click="isCropStamp = false">
        {{ $t('cancel') }}
      </el-button>
      <el-button
        round
        type="primary"
        @click="handleUploadStampOnClick"
        :loading="isStampBtnLoading"
      >
        {{ $t('upload') }}
      </el-button>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import baseTableMixin from '@/mixins/base-table-mixin'
import PhoneInput from '@/components/PhoneInput'
import { mapState } from 'vuex'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { dataURLtoBlob, displayImgCropperRotateBtn, isValidImoNo } from '@/utils'
export default {
  mixins: [baseTableMixin],
  components: { PhoneInput, Cropper },
  data () {
    const rule = {
      pattern: /^[0-9A-Z^$.*+ -?=![\]|/()]*$/,
      message: 'Must be capital letters, numbers or special symbols',
      trigger: 'blur'
    }
    const REG_PASSWORD =
      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[~!#@$%^*&()_+{}=\-\[\]|\\;:'",<.>\/?])[a-zA-Z\d~!#@$%^*&()_+{}=\-\[\]|\\;:'",<.>\/?]*$/;

    const userPassValidate = (rule, value, callback) => {
      if (!value) { return callback(new Error("This field value cannot be empty")); }
        if (value.toString().length < 8 || value.toString().length > 32) {
            callback(new Error("8 to 30 bits in length"));
        } else if (!REG_PASSWORD.test(value)) {
            callback(new Error("Password at least one number, uppercase, lowercase, special characters, 8 to 30 bits in length"));
        } else {
          callback();
        }
    };
    const equalToPassword = (rule, value, callback) => {
      if (!value) { return callback(new Error("This field value cannot be empty")); }
      if (this.userForm.password !== value) {
        callback(new Error("Must be the same as the password"));
      } else {
        callback();
      }
    }
    return {
      fileUploadUrl: this.$apis.baseUrl + this.$apis.uploadFile,
      typeOptions: [
        { value: 'Bulk Carrier' },
        { value: 'General Cargo' },
        { value: 'Container Ship' },
        { value: 'Reefer' },
        { value: 'Ro-Ro' },
        { value: 'Chemical Tanker' },
        { value: 'Oil Tanker' },
        { value: 'Product Tankers' },
        { value: 'LPG Tanker' },
        { value: 'Bunker Barge' },
        { value: 'Livestock Carrier' },
        { value: 'Heavy Lift' },
        { value: 'Tugs' },
        { value: 'Vehicle Carrier' },
        { value: 'LNG Tanker' }
      ],
      orderItem: {},
      isValid: true,
      userForm: {},
      submitLoading: false,
      uuidDatas: {},
      fileList: [],
      rules: {
        shipName: [
          { required: true, message: this.$t('rejectBeEmpty'), trigger: 'blur' },
          rule
        ],
        callSign: [rule],
        registryPort: [rule],
        licenceNo: [{
          pattern: /^S[A|B|C|P|R|T]{1} [0-9]{4}[a-zA-Z]{1}$/,
          message: 'Invalid license number. Valid format: S[A|B|C|P|R|T] NNNNA',
          trigger: 'blur'
        }],
        sealVerificationReportNo: [
          { required: true, message: 'Please enter seal verification report no.', trigger: 'blur' },
          rule
        ],
        meterSerialNo: [
          { required: true, message: 'Please enter meter serial no.', trigger: 'blur' },
          rule
        ],
        password: [
            { required: true, validator: userPassValidate, trigger: "change" },
            ],
        confirmPassword: [
          { required: true, validator: equalToPassword, trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please enter email', trigger: 'blur' }
        ],
        imo: [
          {
            validator: (rule, value, callback) => {
              if (!(!value || value.trim() === '') && !isValidImoNo(value)) {
                callback('Invalid IMO No.')
              }
              callback()
            },
            trigger: ['change', 'blur']
          }
        ],
        type: [
          { required: true, message: 'Please enter type', trigger: 'change' }
        ]
      },
      stampFile: null,
      croppedStampFile: null,
      isCropStamp: false,
      croppedStampCanvas: null,
      croppedStampCoordinates: null,
      isStampLoading: false,
      isStampBtnLoading: false
    }
  },
  computed: {
    ...mapState({
      currentTheme: 'currentTheme'
    })
  },
  methods: {
    dataURLtoBlob,
    handleStampCropChange ({ coordinates, canvas }) {
      this.croppedStampCanvas = canvas
      this.croppedStampCoordinates = coordinates
    },
    handleUploadStampOnClick () {
      this.isStampBtnLoading = true
      const dataUrl = this.croppedStampCanvas.toDataURL(this.stampFile.raw.type)
      const blob = this.dataURLtoBlob(dataUrl)
      const file = new File([blob], this.stampFile.name)

      const formData = new FormData()
      formData.append('file', file)

      this.$request.post({
        url: this.$apis.uploadShipStamp,
        data: formData
      }).then(res => {
        this.croppedStampFile = res.data
        this.croppedStampFile.url = URL.createObjectURL(blob)
        this.isCropStamp = false
        this.$message.success(this.$t('saveSuccess'))
      })
    },
    onUploadStamp (file) {
      this.isStampLoading = true
      this.stampFile = file
      this.isCropStamp = true
      this.isStampBtnLoading = false
    },
    handleCropperReady () {
      this.isStampLoading = false
      displayImgCropperRotateBtn(this.$refs.cropper)
    },
    getParamByUUid () {
      this.$request.post({
        url: this.$apis.getInviteShipParamByUUid,
        data: {
          uuid: this.$route.query.uuid || ''
        }
      }).then(res => {
        if (res?.code === 1000) {
          this.uuidDatas = res.data
          this.userForm = Object.assign({}, this.userForm, {
            email: this.uuidDatas.email || '',
            imo: this.uuidDatas.imo || '',
            tenantId: this.uuidDatas.tenantId || ''
          })
        } else {
          this.isValid = false
          // this.$message.error(res.message)
        }
      })
    },
    submitForm () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          if (this.userForm.password !== this.userForm.confirmPassword) {
            this.$message.error('Password does not match!')
            return false
          }
          this.submitLoading = true
          this.$request.post({
            url: this.$apis.saveInvitationShip,
            data: { ...this.userForm, uuid: this.$route.query.uuid || '', stampId: this.croppedStampFile ? this.croppedStampFile.id : null }
          }).then(res => {
            if (res?.code === 1000) {
              this.$message.success('Register was successful!')
              this.$router.replace('/login')
            } else {
              this.$message.error(res.message)
            }
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          this.$message.error('Please complete the form information!')
          return false
        }
      })
    },
    resetForm () {
      this.croppedStampFile = null
      this.$refs.userForm.resetFields()
      this.userForm = Object.assign({}, this.userForm, {
        email: this.uuidDatas.email || '',
        imo: this.uuidDatas.imo || '',
        tenantId: this.uuidDatas.tenantId || ''
      })
    }
  },
  mounted () {
    this.getParamByUUid()
    this.$i18n.locale = 'en'
    localStorage.language = 'en'
  }
}

</script>

<style lang="scss">
  .agent-form {
    body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, p, blockquote, table, th, td, embed, object {
      padding: 0;
      margin: 0;
    }
    p {
      padding: 3px 0;
      color: #909399;
    }
    .el-header {
      @include flex-center-row;
      padding: 0;
      justify-content: flex-start;
      background-color: $primary-color;
      .logo-container {
        @include flex-center-row;
        box-sizing: border-box;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        box-shadow: 1px 0 2px 0 rgba(0, 0, 0, .05);
        padding-left: 20px;

        img {
          height: 30px;
        }

        span {
          margin-left: 20px;
          white-space: nowrap
        }
      }
    }
    .container {
      width: 1200px;
      min-height: calc(100vh - 55px);
      box-sizing: border-box;
      margin: 0 auto;
      padding: 10px 25px;
      background-color: #F4F6FC;
      padding-bottom: 50px;
      .el-form--label-top .el-form-item__label {
        padding: 0 0 0 10px;
      }
      .el-form-item {
        margin-bottom: 12px;
        .el-form-item__error {
          padding: 5px;
        }
        .el-input__inner {
          padding: 5px 8px;
        }
      }
      .main-text {
        padding: 20px 0;
        color: #303133;
      }
      .order-info {
        margin-bottom: 20px;
        p {
          display: flex;
          .label {
            width: 40%;
            padding-left: 30px;
            box-sizing: border-box;
          }
        }
      }
      .el-card.box-card {
        border: 0;
        position: relative;
        overflow: unset;
        border-radius: 10px;
        margin-top: 25px;
        background-color: #fff;
        &.is-always-shadow, &.is-hover-shadow:focus, &.is-hover-shadow:hover {
          box-shadow: 0 0 0 0;
        }
        .el-card__header {
          padding: 0;
          border-bottom: 0;
          display: flex;
          .clearfix {
            position: relative;
            padding: 5px 10px;
            font-size: 13px;
            font-weight: 700;
            background-color: #3e95bf;
            border-radius: 5px 0 5px 0;
            color: #fff;
            box-shadow: 1px 2px 5px #ccc;
            z-index: 1;
            margin-top: -1px;
            margin-left: -1px;
          }
        }
        .el-card__body {
          padding: 15px 20px;
          .el-upload {
            width: 100%;
            .upload-btn {
              width: 100%;
              padding: 10px 15px;
            }
          }
        }
      }
      .one-tip {
        padding: 25px 10px;
      }
      .btns {
        display: flex;
        justify-content: center;
        .btn {
          margin: 30px 20px 20px 25px;
          padding: 11px 100px;
          font-size: 16px;
        }
      }
    }
    .no-valid {
      text-align: center;
      padding: 40px;
      font-size: 58px;
      font-weight: 400;
      color: #C0C4CC;
      letter-spacing: 5px;
    }
    .el-select {
      width: 100%;
    }
  }
.div-stamp-preview {
  height: 150px;
  width: 150px;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px !important;
  >img {
    max-height: 150px;
    max-width: 150px;
  }
  >div {
    word-break: break-word;
    text-align: center;
  }
}
.el-upload-list {
  display: none;
}
.cropper {
  height: 600px;
  width: 600px;
  border: 1px solid gray;
}
.vue-advanced-cropper__background {
  background: white;
}
.vue-advanced-cropper__foreground {
  background: white;
}
.vue-line-wrapper.vue-line-wrapper--south,
.vue-line-wrapper.vue-line-wrapper--north
{
  background: darkgray;
  height: 2px;
}
.vue-line-wrapper.vue-line-wrapper--east,
.vue-line-wrapper.vue-line-wrapper--west {
  background: rgb(135, 135, 135);
  width: 2px;
}
.vue-simple-handler {
  background: rgb(88, 88, 88);
}
</style>
